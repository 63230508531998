import Vue from 'vue'
import axios from 'axios'

$(() => {
  if (!$('.js-nearest-station-select-modal').length) {
    return
  }

  // memo
  // js-nearest-station-select-modal > モーダル
  // .js-nearest-station-select-modal_open > エリアモーダルを開く
  // .js-nearest-station-select-modal_close > エリアモーダルを閉じる
  // .js-nearest-station-select-modal_value > エリア選択された値を表示するinput
  // .js-nearest-station-select-modal_params > エリア選択された値のhidden input

  const setupComponent = (id) => {

    new Vue({
      el: id,
      data: {
        // select data
        selectedPrefecture: null,
        selectedLine: null,
        selectedStations: $(id).data('default'), // { line_cd: string, line_name: string, station_name: string, station_cd: string, walk_from_station: integer }
        // data
        areas: $(id).data('area'), 
        companiesWithLines: [],
        stations: [],
        // loading
        isLoadingLine: false,
        isLoadingStation: false,
        // error
        rules: $(id).data('rules'), // Validルール
        errors: [], // Validエラーメッセージ
        // option
        withItemCount: $(id).data('with-item-count') // item件数の表示
      },
      mounted() {
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
        $('.js-object-search-form .js-form-clear').on('click', (e) => {
          // form clear
          this.selectedStations = []
        })
      },
      methods: {
        fetchCompaniesWithLines() {
          this.companiesWithLines = []
          this.isLoadingLine = true
          axios
            .get('/api/v1/m_station_companies', {
              params: {
                prefecture_id: this.selectedPrefecture.id,
                with_item_count: this.withItemCount
              }
            })
            .then(resp => {
              this.isLoadingLine = false
              this.companiesWithLines = resp.data.m_station_companies
            })
        },
        fetchStations() {
          this.stations = []
          this.isLoadingStation = true
          axios
            .get('/api/v1/m_stations', {
              params: {
                prefecture_id: this.selectedPrefecture.id,
                line_cd: this.selectedLine.line_cd,
                with_item_count: this.withItemCount
              }
            })
            .then(resp => {
              this.isLoadingStation = false
              this.stations = resp.data.m_stations
            })
        },
        validForm() {
          this.errors = []
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          if (this.rules.includes('presence')) {
            if (this.selectedStations.length == 0) {
              this.errors.push("少なくとも1件以上選択してください")
            }
          }
          if (this.rules.includes('presence_walk_from_station')) {
            if ($('.js-form-validation .submit').hasClass('disabled')) {
              for (let selectedStation of this.selectedStations) {
                if (selectedStation.walk_from_station == null || selectedStation.walk_from_station.length == 0) {
                  this.errors.push("徒歩時間を入力してください")
                  break
                }
              }
            }
          }
          $(id).find('input').trigger('change')
        },
        // disp
        isCheckedSelectedStation(station) {
          for (let selectedStation of this.selectedStations) {
            if (selectedStation.station_cd == station.station_cd) {
              return true
            }
          }
          return false
        },
        // 表示stationすべてにチェックが入ってるか
        isSelectedAllStations() {
          for (let station of this.stations) {
            let isSelectedStation = false
            if (this.withItemCount && station.item_count == 0) {
              // チェック扱い
              isSelectedStation = true
            }
            for (let selectedStation of this.selectedStations) {
              if (isSelectedStation) {
                continue
              }
              if (selectedStation.station_cd == station.station_cd) {
                isSelectedStation = true
              }
            }
            if (!isSelectedStation) {
              return false
            }
          }
          return true
        },
        dispSelectedStationShort() {
          let names = []
          var sortedStations = this.selectedStations.slice();
          sortedStations.sort((a, b) => a.station_cd - b.station_cd);

          for (let selectedStation of sortedStations) {
            names.push(selectedStation.station_name)
          }
          return names.join("、")
        },
        dispSelectedStationCdParams() {
          let names = []
          for (let selectedStation of this.selectedStations) {
            names.push(selectedStation.station_cd)
          }
          return names.join(",")
        },
        // event
        onSelectPrefecture(prefecture) {
          $('.modal-body-pages').removeClass('back-page-1')
          $('.modal-body-pages').addClass('go-page-2')
          $(".modal-body-pages").scrollTop(0)
          this.selectedPrefecture = prefecture
          this.fetchCompaniesWithLines()
        },
        onSelectLine(mStationLine) {
          $('.modal-body-pages').removeClass('go-page-2').removeClass('back-page-2')  
          $('.modal-body-pages').addClass('go-page-3')
          $(".modal-body-pages").scrollTop(0)
          this.selectedLine = mStationLine
          this.fetchStations()
        },
        onSelectStation(station) {
          for (let i = 0; i < this.selectedStations.length; i++) {
            let selectedStation = this.selectedStations[i]
            if (selectedStation.station_cd == station.station_cd) {
                // チェック済みは削除
                this.selectedStations.splice(i, 1)
                return
            }
          }
          // 未チェックは追加
          this.selectedStations.push({
            line_cd: this.selectedLine.line_cd,
            line_name: this.selectedLine.line_name,
            station_cd: station.station_cd,
            station_name: station.station_name,
            walk_from_station: null,
          })
          this.validForm()
        },
        onSelectAllStations() {
          if (this.isSelectedAllStations()) {
            // 既に選択済みの場合はすべてチェック外す
            for (let station of this.stations) {
              for (let i = 0; i < this.selectedStations.length; i++) {
                if (station.station_cd == this.selectedStations[i].station_cd) {
                  this.selectedStations.splice(i, 1)
                  break
                }
              }
            }
            return
          } else {
            // 未選択の場合
            for (let station of this.stations) {
              let isAlreadSelectedStation = false
              for (let i = 0; i < this.selectedStations.length; i++) {
                if (station.station_cd == this.selectedStations[i].station_cd) {
                  isAlreadSelectedStation = true
                  break
                }
              }
              if (isAlreadSelectedStation) {
                // チェック済みはスキップ
              } else if(this.withItemCount && station.item_count == 0) {
                // 0件のitemはスキップ
              } else {
                this.onSelectStation(station)
              }
            }
          }

          this.validForm()
        },
        onBackToArea() {
          $('.modal-body-pages').removeClass('go-page-2').removeClass('back-page-2')
          $('.modal-body-pages').addClass('back-page-1') 
          $(".modal-body-pages").scrollTop(0)
        },
        onBackToLine() {
          $('.modal-body-pages').removeClass('go-page-3')
          $('.modal-body-pages').addClass('back-page-2')  
          $(".modal-body-pages").scrollTop(0)
        },
        onDeleteSelectedStation(index) {
          this.selectedStations.splice(index, 1)
          this.validForm()
        },
        onFormChange() {
          this.validForm()
        }
      }
    })
  }

    // モーダル表示イベント
  $(document).on("click", ".js-nearest-station-select-modal_open", function (e) {
    const elem = document.activeElement;
    elem.blur();
    $(e.target).closest('.js-nearest-station-select-modal').find('.modal').modal('show')
  })
  // モーダル閉じるイベント
  $(document).on("click", ".js-nearest-station-select-modal_close", function (e) {
    $(e.target).closest('.js-nearest-station-select-modal').find('.modal').modal('hide')
  })
    
  // 初期化
  $('.js-nearest-station-select-modal').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
