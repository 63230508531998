import Vue from 'vue'
import draggable from 'vuedraggable'
import axios from 'axios'

$(() => {
  if (!$('#admin-json_generators_index').length) {
    return
  }

  const setupComponent = (id) => {
    new Vue({
      el: id,
      components: {
        'draggable': draggable,
      },
      data: {
        jsonType: $(id).data('type'), // form_json, list_json
        attrs: [], // key, label, type, ...
        formConfigs: [],
        formJson: "", 
        editingModal: {
          confIndex: null,
          confKey: '',
          value: {},
        },
      },
      mounted() {
        let that = this

        // setup
        this.fetchAttrs(() => {
          this.setupFormJson()
        })

        // set jquery event
        $('#attr-modal .close').on('click', () => {
          that.fetchAttrs(() => {
            // nothing
          }) 
        })
      },
      methods: {
        fetchAttrs(callback) {
          let params = {
            site_id: $(id).data('site_id'),
            attr: $(id).data('attr')
          }
          axios
            .get('/api/v1/attrs', {
              params: params
            })
            .then(resp => {
              this.setAttrs(resp.data)
              callback()
            })
        },
        setAttrs(data) {
          this.attrs = []
          for (let label_key in data) {
            if (label_key.includes('_label')) { // labelのみ扱う
              let label = data[label_key]
              if (!!label) { // labelがnullでなければ扱う
                let key = label_key.replace('_label', "")
                this.attrs.push({
                  key: key,
                  label: label,
                  type: data[`${key}_type`]
                })
              }
            }
          }
        },
        formatToJson() {
          let params = []

          for (let conf of this.formConfigs) {
            const param = Object.assign({}, conf);
            params.push(param)
          }

          if (this.jsonType == 'form_json' || this.jsonType == 'search_json') {
            return JSON.stringify({
              "default": {
                "form_group": [
                  {
                    "form": params 
                  }
                ]
              }
            })
          } else if (this.jsonType == 'list_json' || this.jsonType == 'detail_json') {
            return JSON.stringify({
              "default": {
                "contents_group": [
                  {
                    "contents": params 
                  }
                ]
              }
            })
          }
        },
        /**********************************************************
         * Formイベント
         **********************************************************/
        setupFormJson() {
          let defaultJson = $(id).data('form_json')
          let forms = []
          if (this.jsonType == 'form_json' || this.jsonType == 'search_json') {
            forms = defaultJson.default.form_group[0].form
          } else if (this.jsonType == 'list_json' || this.jsonType == 'detail_json') {
            forms = defaultJson.default.contents_group[0].contents
          }
          for (let form of forms) {
            this.formConfigs.push(form)
          }
        },
        // デフォルトの値を補完する
        dispOriginLabel(key) {
          for (let attr of this.attrs) {
            if (key === attr.key) {
              return attr.label
            }
          }
        },
        isSelectedAttrToForm(attr) {
          for (let conf of this.formConfigs) {
            if (conf.key === attr.key) {
              return true
            }
          }
          return false
        },
        onAddAttrToForm(attr) {
          if (this.isSelectedAttrToForm(attr)) {
            return
          }
          let newConfig = {
            key: attr.key,
          }
          if (this.jsonType == 'form_json') {
            newConfig['data'] = { rules: [] }
          }
          if (this.jsonType == 'detail_json') {
            newConfig["wrapper_class"] = "mc-object-wrapper-table"
          }
          this.formConfigs.push(newConfig)
        },
        onDeleteAttrFromForm(index) {
          this.formConfigs.splice(index, 1)
        },
        onEditConfig(conf, index) {
          this.editingModal.confIndex = index
          this.editingModal.confKey = conf.key
          this.editingModal.value = JSON.stringify(conf, null, 4);
          $('#form-config-modal').modal('show')
        },
        onUpdateConfig() {
          let text = $('#form-config-modal textarea').val()
          let value = JSON.parse(text)
          Vue.set(this.formConfigs, this.editingModal.confIndex, value)
          $('#form-config-modal').modal('hide')
        }
      },
    })
  }

  
  setupComponent('#js-generator')
})
