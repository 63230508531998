import Sortable from 'sortablejs';

$(() => {
  if (!$('#mypage-users_new').length) {
    if (!$('#mypage-users_edit').length) {
      if (!$('#mypage-users_create').length) {
        if (!$('#mypage-users_update').length) {
          return
        }
      }
    }
  }

  // sortable
  if ($('#profile-history-fields').length > 0)  {
    $(() => {
      Sortable.create(
        document.getElementById('profile-history-fields'),
        {
          handle: '.js-sort-handler',
          store: {
            set: () => {
              $('#profile-history-fields .js-profile-history-fields-sort').each((i, e) => {
                $(e).val(i)
              })
            }
          }
      });
    } );
  }

  // sortable
  if ($('#profile-part-fields').length > 0)  {
    $(() => {
      Sortable.create(
        document.getElementById('profile-part-fields'),
        {
          handle: '.js-sort-handler',
          store: {
            set: () => {
              $('#profile-part-fields .js-profile-part-fields-sort').each((i, e) => {
                $(e).val(i)
              })
            }
          }
      });
    } );
  }
});