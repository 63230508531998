import Vue from 'vue'

$(() => {
  if (!$('.js-multiple-area-select-modal').length) {
    return
  }

  // memo
  // js-multiple-area-select-modal > モーダル
  // .js-multiple-area-select-modal_open > エリアモーダルを開く
  // .js-multiple-area-select-modal_close > エリアモーダルを閉じる
  // .js-multiple-area-select-modal_value > エリア選択された値を表示するinput
  // .js-multiple-area-select-modal_params > エリア選択された値のhidden input

  const setupComponent = (id) => {

    let valueTag = $(id).closest('.js-multiple-area-select-modal_wrapper').find('div.js-multiple-area-select-modal_value')
    let valueInput = $(id).closest('.js-multiple-area-select-modal_wrapper').find('input.js-multiple-area-select-modal_value')
    let paramsInput = $(id).closest('.js-multiple-area-select-modal_wrapper').find('.js-multiple-area-select-modal_params')

    new Vue({
      el: id,
      data: {
        openedRegion: "",
        selectedAreaIds: [],
        datas: $(id).data('data'), 
        // option
        withItemCount: $(id).data('with-item-count') // item件数の表示
      },
      mounted() {
        this.openedRegion = this.datas[0].region_name
        let params = paramsInput.val()
        for (let param of params.split(',')) {
          if (param) {
            this.selectedAreaIds.push(parseInt(param))
          }
        }
        this.updateParams()

        $('.js-object-search-form .js-form-clear').on('click', (e) => {
          this.selectedAreaIds = []
          valueInput.val('')
          paramsInput.val('')
        })
      },
      methods: {
        dispIsDisableAllArea(area_group) {
          if (!this.withItemCount) {
            return false
          }

          let includeSelectableAreaGroup = false
          for (let area of area_group.areas) {
            if (area.item_count > 0) {
              includeSelectableAreaGroup = true
            }
          }
          return !includeSelectableAreaGroup
        },
        dispIsSelectedAllArea(area_group) {
          if (this.dispIsDisableAllArea(area_group)) {
            return false
          }

          for (let area of area_group.areas) {
            if (this.withItemCount && area.item_count == 0) {
              // チェック扱い
              continue
            }
            if (!this.selectedAreaIds.includes(area.id)) {
              return false
            }
          }
          return true
        },
        dispSelectedCountInRegion(region_name) {
          let count = 0
          for (let data of this.datas) {
            if (data.region_name !== region_name) {
              continue
            }
            for (let area_group of data.area_groups) {
              for (let area of area_group.areas) {
                if (this.selectedAreaIds.includes(area.id)) {
                  count += 1
                }
              }
            }
          }
          return count
        },
        updateParams() {
          // 表示用
          let values = []
          for (let data of this.datas) {
            for (let area_group of data.area_groups) {
              for (let area of area_group.areas) {
                if (this.selectedAreaIds.includes(area.id)) {
                  values.push(area.name)
                }
              }
            }
          }
          if (values.length > 0) {
            valueInput.val(values.join('、'))
            valueTag.html(`<table class="borderless"><tr><td class="pr-3 align-top" style="width:70px"><strong class="text-dark">選択中</strong></td><td>${values.join('　')}</td></tr></table>`)
          } else {
            valueInput.val('')
            valueTag.html('<valueTag class="mc-secondary">エリアが未選択です</valueTag>')
          }

          // パラメータ用
          let params = [] 
          for (let select of this.selectedAreaIds) {
            params.push(select)
          }
          paramsInput.val(params.join(','))

          // form-validater発火
          $(id).find('input').trigger('change')
        },
        onSelectRegion(regionName) {
          this.openedRegion = regionName
        },
        onSelectAllArea(area_group) {
          if (this.dispIsSelectedAllArea(area_group)) {
            // 既に選択済みの場合はすべてチェック外す
            for (let area of area_group.areas) {
              this.selectedAreaIds.splice(this.selectedAreaIds.indexOf(area.id), 1)
            }
            this.updateParams()
            return
          }

          // 未選択の場合

          for (let area of area_group.areas) {
            if (this.selectedAreaIds.includes(area.id)) {
              // チェック済みはスキップ
            } else if(this.withItemCount && area.item_count == 0) {
              // 0件のitemはスキップ
            } else {
              // 未チェックは追加
              this.selectedAreaIds.push(area.id)
            }
          }

          // 最後にソート
          this.selectedAreaIds.sort((a,b) => {
              if(a.sort > b.sort) return -1;
              if(a.sort < b.sort) return 1;
              return 0;
          });

          this.updateParams()
        },
        onSelectArea(area) {
          if (this.selectedAreaIds.includes(area.id)) {
            // チェック済みは削除
            this.selectedAreaIds.splice(
              this.selectedAreaIds.indexOf(area.id), 1)
          } else {
            // 未チェックは追加
            this.selectedAreaIds.push(area.id)
            this.selectedAreaIds.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }

          this.updateParams()
        },
      }
    })
  }

    // モーダル表示イベント
  $(document).on("click", ".js-multiple-area-select-modal_open", function (e) {
    const elem = document.activeElement;
    elem.blur();
    $(e.target).closest('.js-multiple-area-select-modal_wrapper').find('.js-multiple-area-select-modal').modal('show')
  })
  // モーダル閉じるイベント
  $(document).on("click", ".js-multiple-area-select-modal_close", function (e) {
    $(e.target).closest('.js-multiple-area-select-modal_wrapper').find('.js-multiple-area-select-modal').modal('hide')
  })
    
  // 初期化
  $('.js-multiple-area-select-modal').each((i, element) => {
    let id = $(element).attr('id')
    setupComponent('#' + id)
  })
})
