$(() => {
  if (!$('.js-form-clear').length) {
    return
  }

  $('.js-form-clear').on('click', (e) => {
    form = $(e.target).closest('form')
    clearForm(form)
    alert('フォームをクリアしました')
  })

  clearForm = (form) => {
    $(form)
      .find('input, select, textarea')
      .not(':button, :submit, :reset, :hidden')
      .val('')
      .prop('checked', false)
      .prop('selected', false)
    
    $(form).find("input[type='checkbox']:checked").prop('checked', false)
    $(form).find(':radio').filter('[data-default]').prop('checked', true)
  }
})
