import Vue from 'vue'

$(() => {
  if (!$('.js-multiple-checkbox').length) {
    return
  }

  const multipleCheckbox = (id) => {
    new Vue({
      el: id,
      data: {
        rules: $(id).data('rules'), // Validルール
        selected: $(id).data('selected'),
        choices: $(id).data('choices'), // SiteChoiceオブジェクトのArray
        disabled: $(id).data('disabled'),
        maxSelectableCount: $(id).data('max-selectable-count'),
        errors: [], // Validエラーメッセージ
      },
      mounted() {
        if (!this.maxSelectableCount) {
          this.maxSelectableCount = 30 
        }
        $('.js-form-validation .submit').on('click', (e) => {
          this.validForm()
        })
      },
      methods: {
        dispFormValue() {
          // [1,2] → ["1", "2"]
          let value = [] 
          for (let select of this.selected) {
            value.push(new String(select))
          }
          return JSON.stringify(value)
        },
        validForm() {
          this.errors = []
          if (this.selected.length > this.maxSelectableCount) {
            this.errors.push(`${this.maxSelectableCount}件以内で選択してください（選択中：${this.selected.length}件}`)
          }
          if (!this.rules || this.rules.length == 0) {
            // 未定義はリターン
            return
          }
          if (this.selected.length == 0) {
            this.errors.push("少なくとも1件以上選択してください")
          }
        },
        onSelectChoice(choice) {
          if (!!this.disabled) {
            return
          }
          if (this.selected.includes(choice.id)) {
            // チェック済みは削除
            this.selected.splice(
              this.selected.indexOf(choice.id), 1)
          } else {
            // 未チェックは追加
            this.selected.push(choice.id)
            this.selected.sort((a,b) => {
                if(a.sort > b.sort) return -1;
                if(a.sort < b.sort) return 1;
                return 0;
            });
          }
          this.validForm()
        },
      },
    })
  }

  $('.js-multiple-checkbox').each((i, element) => {
    const id = $(element).attr('id')
    multipleCheckbox('#' + id)
  })
})
